import { useEffect, useState } from 'react';
import { Modal } from '#ui-kit';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import debug from '#services/debug.service';
import { SettingsService } from '#services/settings';
import { SettingForm } from './containers/Form';
import { SettingsTable } from './containers/Table';
import { ListedSettingResponseDtoType, PaginationRequestDto } from 'types';

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<ListedSettingResponseDtoType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const settingsService = new SettingsService();
  const [selectedSettingKey, setSelectedSettingKey] = useState<string | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenModal = (key: string) => {
    setSelectedSettingKey(key);
    setIsModalOpen(true);
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);

      const params: PaginationRequestDto = {
        page,
        size,
      };

      const response = await settingsService.getFilteredSettings(params);

      setSettings((old) => {
        if (page === 1) {
          return response.data;
        } else {
          return [...old, ...response.data];
        }
      });

      setTotalPages(Math.ceil(response.total / response.size));
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getRestaurants ~ err:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  useEffect(() => {
    getSettings();
  }, [page, size]);

  if (isLoading || !settings?.length) {
    return <LoadingContent />;
  }

  return (
    <>
      {selectedSettingKey ? (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <SettingForm settingKey={selectedSettingKey} />
        </Modal>
      ) : (
        <></>
      )}
      <Head
        {...{
          label: 'Настройки приложения',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <SettingsTable
        totalPages={totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        data={settings}
        isLoading={isLoading}
        onEdit={handleOpenModal}
      />
    </>
  );
};
