import * as Yup from 'yup';
import { ClientPlatformEnum, ClientVersionEnum } from 'types';

export const validationSchema = Yup.object().shape({
  platform: Yup.string()
    .oneOf(Object.values(ClientPlatformEnum), 'Выберите платформу')
    .required('Выберите платформу'),
  status: Yup.string()
    .oneOf(Object.values(ClientVersionEnum), 'Выберите статус')
    .required('Выберите статус'),
  version: Yup.string()
    .matches(/^\d+\.\d+\.\d+$/, 'Должно быть в формате "число.число.число"')
    .required('Укажите версию'),
});

export const initialValues = {
  platform: undefined,
  status: undefined,
  version: undefined,
};
