import { useState } from 'react';
import { toastify } from '#ui-kit';
import { ClientVersionsService } from '#services/client';
import debug from '#services/debug.service';
import { CreateClientVersionPayloadType } from 'types';

const useClientVersions = () => {
  const clientService = new ClientVersionsService();

  const [loading, setLoading] = useState<boolean>(false);

  const createClientVersion = async (
    payload: CreateClientVersionPayloadType,
  ) => {
    setLoading(true);

    try {
      await clientService.createClientVersion(payload);

      toastify.success('Версия приложения успешно создана');
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useClientVersions ~ getClientVersions ~ err:', err);

      toastify.error('Не удалось создать версию приложения');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createClientVersion,
  };
};

export default useClientVersions;
