import { buildQueryFromObject } from '#utils';
import apiService from '#services/api';
import {
  ClientPlatformEnum,
  ClientVersionResponse,
  CreateClientVersionPayloadType,
  GetClientVersionsListFilter,
  UpdateClientVersionPayload,
} from 'types';
import { ClientVersionsListResponse } from 'types/dto/client/client-varsions-list.response.dto';

export class ClientVersionsService {
  private readonly API_ROUTES = {
    getClientVersions: () => 'client',
    createClientVersion: () => 'client',
    updateClientVersion: (platform: ClientPlatformEnum, version: string) =>
      `client/${platform}/${version}`,
  };

  public async updateClientVersion(
    platform: ClientPlatformEnum,
    version: string,
    payload: UpdateClientVersionPayload,
  ) {
    try {
      const request = await apiService.request.patch(
        this.API_ROUTES.updateClientVersion(platform, version),
        {
          body: JSON.stringify(payload),
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: ClientVersionResponse = await request.json();

      console.log(result, 'result from updateClientVersion');

      return result;
    } catch (err) {
      console.log(err, 'error from updateClientVersion');

      throw err;
    }
  }

  public async getClientVersions(filter?: GetClientVersionsListFilter) {
    try {
      const request = await apiService.request.get(
        this.API_ROUTES.getClientVersions(),
        {
          searchParams: buildQueryFromObject(filter),
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: ClientVersionsListResponse = await request.json();

      console.log(result, 'result from getClientVersions');

      return result;
    } catch (err) {
      console.log(err, 'error from getClientVersions');

      throw err;
    }
  }

  public async createClientVersion(payload: CreateClientVersionPayloadType) {
    try {
      const request = await apiService.request.post(
        this.API_ROUTES.createClientVersion(),
        {
          body: JSON.stringify(payload),
          headers: {
            'content-type': 'application/json',
          },
        },
      );

      const result: ClientVersionResponse = await request.json();

      console.log(result, 'result from createClientVersion');

      return result;
    } catch (err) {
      console.log(err, 'error from createClientVersion');

      throw err;
    }
  }
}
