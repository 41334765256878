import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonContainer, ButtonsSection } from './styles';
import {
  Button,
  ConfirmDialog,
  Icon,
  Modal,
  Table,
  toastify,
  Typography,
} from '#ui-kit';
import {
  ButtonSize,
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import { notAvailableValue } from '#utils';
import debug from '#services/debug.service';
import { FranchisingService } from '#services/franchising';
// import { RestaurantsService } from '#services/restaurants';
import { AddressesContainer } from './containers/Addresses';
import { BankAccountContainer } from './containers/BankAccount';
import { CeoContainer } from './containers/Ceo';
import { CommonInfoContainer } from './containers/CommonInfo';
import FranchisorShopForm from './containers/Form';
import { FoundersContainer } from './containers/Founders';
import { LicensesContainer } from './containers/Licenses';
import { PhonesContainer } from './containers/Phones';
import { FranchisorShopRestaurantsContainer } from './containers/Restaurants';
import IconsNames from 'components/ui-kit/Icons/types/Icons.names';
import { FranchisorShopResponseDto } from 'types';

export default () => {
  const [franchisorShop, setFranchisorShop] =
    useState<FranchisorShopResponseDto>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmExportDialogOpen, setIsConfirmExportDialogOpen] =
    useState<boolean>(false);
  const { id } = useParams();

  const franchisingService = new FranchisingService();

  const handleCloseConfirmExportDialog = () =>
    setIsConfirmExportDialogOpen(false);

  const handleOpenConfirmExportDialog = () =>
    setIsConfirmExportDialogOpen(true);

  const getFranchisorShop = async () => {
    try {
      setIsLoading(true);

      if (!id || !+id) {
        return;
      }

      const response = await franchisingService.getFranchisorShop(+id);

      setFranchisorShop(response);
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getFranchisorShop ~ err:', e);

      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFranchisorShop();
  }, []);

  const handleCloseModal = async () => {
    setIsModalOpen(false);

    await getFranchisorShop();
  };

  const handleExportFranchisorShop = async () => {
    if (id) {
      try {
        setIsExportLoading(true);

        const result = await franchisingService.exportFranchisorShop(+id);

        setFranchisorShop(result);

        toastify.success('Торговая точка успешно экспортирована');
      } catch (error: any) {
        toastify.error(
          error?.message ?? 'Не удалось экспортировать торговую точку',
        );
      } finally {
        setIsExportLoading(false);
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  if (isLoading || !franchisorShop) {
    return <LoadingContent />;
  }

  return (
    <>
      <Head
        {...{
          label: 'Редактирование торговой точки партнера',
          underlineVariant: HeadUnderlineVariant.Secondary,
          button: {
            label: 'Редактировать',
            onClick: handleOpenModal,
          },
        }}
      />
      <ConfirmDialog
        open={isConfirmExportDialogOpen}
        message={'Вы действительно хотите повторить экспорт?'}
        onClose={handleCloseConfirmExportDialog}
        onConfirm={handleExportFranchisorShop}
      />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <FranchisorShopForm
          initialData={franchisorShop}
          onClose={handleCloseModal}
        />
      </Modal>
      <ButtonsSection>
        <ButtonContainer>
          <Icon name={IconsNames['t-bank']} height={34} width={95} />
          <Button
            isLoading={isExportLoading}
            label="Повторить экспорт торговой точки"
            size={ButtonSize.Medium}
            onClick={handleOpenConfirmExportDialog}
          />
          {franchisorShop?.externalId ? (
            <Typography
              size={TypographySize.s}
              tag={TypographyTag.p}
              style={{ marginTop: 10 }}
            >
              Внимание! Магазин уже экспортирован
            </Typography>
          ) : (
            <></>
          )}
        </ButtonContainer>
      </ButtonsSection>

      {id ? (
        <FranchisorShopRestaurantsContainer franchisorShopId={+id} />
      ) : (
        <></>
      )}

      <CommonInfoContainer data={franchisorShop} />

      <AddressesContainer data={franchisorShop?.addresses} />

      <PhonesContainer data={franchisorShop?.phones} />

      <FoundersContainer data={franchisorShop?.founders} />

      <CeoContainer data={franchisorShop?.ceo} />

      <LicensesContainer data={franchisorShop?.licenses} />

      <BankAccountContainer data={franchisorShop?.bankAccount} />

      <Typography
        size={TypographySize.xl}
        tag={TypographyTag.span}
        style={{ marginTop: 40, marginBottom: 20 }}
      >
        Признак фискализации
      </Typography>
      <Table
        header={{
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Bold}
                >
                  Название компании
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              onClick: () => {},
              children: (
                <Typography
                  size={TypographySize.m}
                  tag={TypographyTag.span}
                  weight={TypographyWeight.Bold}
                >
                  URL для нотификаций
                </Typography>
              ),
            },
          },
        }}
        rows={
          franchisorShop?.fiscalization
            ? [
                {
                  cells: {
                    0: {
                      width: 232,
                      align: TableCellAlign.Left,
                      onClick: () => {},
                      children: (
                        <Typography
                          size={TypographySize.m}
                          tag={TypographyTag.span}
                          weight={TypographyWeight.Light}
                        >
                          Признак фискализации 1
                        </Typography>
                      ),
                    },
                    1: {
                      width: 232,
                      align: TableCellAlign.Left,
                      onClick: () => {},
                      children: (
                        <Typography
                          size={TypographySize.m}
                          tag={TypographyTag.span}
                          weight={TypographyWeight.Light}
                        >
                          {notAvailableValue(
                            franchisorShop?.fiscalization?.сompany,
                          )}
                        </Typography>
                      ),
                    },
                    2: {
                      width: 232,
                      align: TableCellAlign.Left,
                      onClick: () => {},
                      children: (
                        <Typography
                          size={TypographySize.m}
                          tag={TypographyTag.span}
                          weight={TypographyWeight.Light}
                        >
                          {notAvailableValue(
                            franchisorShop?.fiscalization?.notifyUrl,
                          )}
                        </Typography>
                      ),
                    },
                  },
                },
              ]
            : []
        }
      />
    </>
  );
};
