import { colors } from '#styles.variables';
import { StatusBadgeVariant } from '#components.types';
import { ClientPlatformEnum, ClientVersionEnum } from 'types';

export const ClientVersionStatusOptions = [
  {
    value: ClientVersionEnum.CURRENT,
    label: 'Текущая',
    color: colors.primary._100,
  },
  {
    value: ClientVersionEnum.MAINTAINED,
    label: 'Поддерживаемая',
    color: colors.warning._100,
  },
  {
    value: ClientVersionEnum.OUTDATED,
    label: 'Устаревшая',
    color: colors.black._80,
  },
];

export const ClientVersionBadgeMap = {
  [ClientVersionEnum.CURRENT]: {
    label: 'Текущая',
    variant: StatusBadgeVariant.Active,
  },
  [ClientVersionEnum.MAINTAINED]: {
    label: 'Поддерживаемая',
    variant: StatusBadgeVariant.Warning,
  },
  [ClientVersionEnum.OUTDATED]: {
    label: 'Устаревшая',
    variant: StatusBadgeVariant.Disabled,
  },
};

export const ClientPlatformBadgeMap = {
  [ClientPlatformEnum.ANDROID]: {
    label: 'Android',
    variant: StatusBadgeVariant.Processing,
  },
  [ClientPlatformEnum.IOS]: {
    label: 'IOS',
    variant: StatusBadgeVariant.Active,
  },
};
