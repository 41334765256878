import React from 'react';

import { Routes, Route, Outlet } from 'react-router-dom';

import * as Pages from '#pages';

import { Layout } from '#components';

const Container: React.FC = () => (
  <Layout>
    <Outlet />
  </Layout>
);

const PrivateRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Container />}>
      <Route path="/profile" element={<Pages.Profile />} />

      <Route path="/users" element={<Pages.UsersPage />} />
      <Route path="/users/admins" element={<Pages.AdminsPage />} />
      <Route path="/users/:userId" element={<Pages.User />} />
      <Route path="/users/admins/:userId" element={<Pages.User />} />
      <Route path="/users/admins/create" element={<Pages.CreateNewUser />} />

      <Route path="/support" element={<Pages.Support />} />
      <Route path="/support/:messageId" element={<Pages.SupportMessage />} />

      <Route path="/settings" element={<Pages.Settings />} />

      <Route path="/restaurants" element={<Pages.Restaurants />} />
      <Route path="/delivery-zones" element={<Pages.DeliveryZones />} />
      <Route path="/restaurants/:id" element={<Pages.Restaurant />} />
      <Route
        path="/restaurants/create"
        element={<Pages.CreateNewRestaurant />}
      />
      <Route path="/orders" element={<Pages.Orders />} />
      <Route path="/promotions" element={<Pages.Promotions />} />
      <Route path="/promotions/:id" element={<Pages.Promotion />} />
      <Route path="/documents" element={<Pages.Documents />} />
      <Route path="/orders/:id" element={<Pages.Order />} />
      <Route path="/onbording" element={<Pages.Onbording />} />
      <Route path="/franchising" element={<Pages.Franchising />} />
      <Route path="/franchising/:id" element={<Pages.FranchisorShop />} />
      <Route path="/client" element={<Pages.ClientVersions />} />
      <Route path="*" element={<React.Fragment />} />
    </Route>
  </Routes>
);

export default PrivateRoutes;
