import { useState } from 'react';
import { useClientVersions } from '#hooks';
import { Modal } from '#ui-kit';
import { Head } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import CreateClientVersionForm from './containers/form';
import ClientVersionsTable from './containers/table';

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading, updateClientVersion } = useClientVersions();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Head
        {...{
          label: 'Версии мобильного приложения',
          underlineVariant: HeadUnderlineVariant.Secondary,
          button: {
            label: 'Опубликовать новую версию',
            onClick: handleOpenModal,
          },
        }}
      />
      <ClientVersionsTable
        data={data}
        loading={loading}
        updateClientVersion={updateClientVersion}
      />
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <CreateClientVersionForm onClose={handleCloseModal} />
      </Modal>
    </>
  );
};
