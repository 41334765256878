import React from 'react';

import * as Styled from './styles';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';

import TableCell from '../TableCell';
import { TableRowProps } from './types/TableRow.types';

const TableRow: React.FC<TableRowProps> = ({
  cells = {},
  border,
  to,
  style,
}) => (
  <Styled.TableRowWrapper style={style}>
    {Object.entries(cells).map(([columnId, { children, ...rest }]) => (
      <TableCell
        key={columnId}
        {...{
          ...rest,
          to,
          padding: ptr(24),
          border: border || {
            width: 1,
            style: 'solid',
            color: colors.black._02,
            sides: ['bottom'],
          },
        }}
      >
        {children}
      </TableCell>
    ))}
  </Styled.TableRowWrapper>
);

export default TableRow;
