import React, { FC } from 'react';
import { Formik, Form, getIn } from 'formik';
import { useCreateClientVersion } from '#hooks';
import { Button, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TypographySize,
  TypographyTag,
} from '#ui-kit.types';
import { initialValues, validationSchema } from './form.const';
import {
  ErrorMessage,
  Input,
  Label,
  Section,
  Select,
  SubSection,
} from 'styles';
import {
  ClientPlatformEnum,
  ClientVersionEnum,
  CreateClientVersionPayloadType,
} from 'types';

const CreateClientVersionForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { loading, createClientVersion } = useCreateClientVersion();

  const onSubmit = async (data: Partial<CreateClientVersionPayloadType>) => {
    await createClientVersion(data as CreateClientVersionPayloadType);
  };

  return (
    <>
      <Typography
        size={TypographySize.s}
        tag={TypographyTag.p}
        style={{ marginBottom: 50 }}
      >
        * Номер варсии должен иметь вид: N.N.N; и содержать только числа,
        например: 1.0.35 (1.21.1a - не подойдёт).
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleBlur, errors, touched, isValid, dirty }) => (
          <Form>
            <Section>
              <SubSection>
                <Label required htmlFor="version">
                  Номер версии
                </Label>
                <Input
                  id="version"
                  name="version"
                  value={initialValues.version}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'version') && getIn(touched, 'version') && (
                  <ErrorMessage>{getIn(errors, 'version')}</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="status">
                  Статус
                </Label>
                <Select
                  id="status"
                  name="status"
                  value={initialValues.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Выберите статус</option>
                  <option value={ClientVersionEnum.CURRENT}>Текущая</option>
                  <option value={ClientVersionEnum.MAINTAINED}>
                    Поддерживаемая
                  </option>
                  <option value={ClientVersionEnum.OUTDATED}>Устаревшая</option>
                </Select>
                {getIn(errors, 'status') && getIn(touched, 'status') && (
                  <ErrorMessage>{getIn(errors, 'status')}</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="platform">
                  Платформа
                </Label>
                <Select
                  id="platform"
                  name="platform"
                  value={initialValues.platform}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Выберите платформу</option>
                  <option value={ClientPlatformEnum.ANDROID}>Android</option>
                  <option value={ClientPlatformEnum.IOS}>IOS</option>
                </Select>
                {getIn(errors, 'platform') && getIn(touched, 'platform') && (
                  <ErrorMessage>{getIn(errors, 'platform')}</ErrorMessage>
                )}
              </SubSection>
            </Section>

            <SubSection
              style={{ marginTop: '16px', display: 'flex', width: '100%' }}
            >
              <Button
                label="Отмена"
                type="reset"
                variant={ButtonVariant.Error}
                size={ButtonSize.Small}
                onClick={onClose}
              />
              <Button
                label="Сохранить"
                className="button"
                type="submit"
                variant={ButtonVariant.Primary}
                disabled={!isValid || loading || !dirty}
                size={ButtonSize.Small}
              />
            </SubSection>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateClientVersionForm;
