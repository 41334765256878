import { useEffect, useState } from 'react';
import { toastify } from '#ui-kit';
import { ClientVersionsService } from '#services/client';
import debug from '#services/debug.service';
import {
  ClientPlatformEnum,
  GetClientVersionsListFilter,
  UpdateClientVersionPayload,
} from 'types';
import { ClientVersionResponse } from 'types/dto/client/client-version.response.dto';

const useClientVersions = (filters?: GetClientVersionsListFilter) => {
  const clientService = new ClientVersionsService();

  const [data, setData] = useState<ClientVersionResponse[]>();

  const [loading, setLoading] = useState<boolean>(false);

  const getClientVersions = async () => {
    setLoading(true);

    try {
      const result = await clientService.getClientVersions(filters);

      setData(result.data);
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useClientVersions ~ getClientVersions ~ err:', err);

      toastify.error('Не удалось получить список версий приложения');
    } finally {
      setLoading(false);
    }
  };

  const updateClientVersion = async (
    version: string,
    platform: ClientPlatformEnum,
    payload: UpdateClientVersionPayload,
  ) => {
    setLoading(true);

    try {
      await clientService.updateClientVersion(platform, version, payload);

      toastify.success('Версия приложения успешно обновлена');

      await getClientVersions();
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useClientVersions ~ updateClientVersion ~ err:', err);

      toastify.error('Не удалось обновить версию приложения');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientVersions();
  }, []);

  return {
    data,
    loading,
    updateClientVersion,
  };
};

export default useClientVersions;
