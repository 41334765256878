import { colors } from '#styles.variables';
import { IndicatorOption, SingleSelect, Table, Typography } from '#ui-kit';
import {
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { StatusBadge } from '#components';
import { findSelectedOption } from '#utils';
import {
  ClientPlatformBadgeMap,
  ClientVersionStatusOptions,
} from './client-versions-table.const';
import { ClientVersionsTablePropsType } from './types';
import { ClientPlatformEnum, ClientVersionEnum } from 'types';

const getRowBackgroundByStatus = (status: ClientVersionEnum) => {
  switch (status) {
    case ClientVersionEnum.CURRENT:
      return colors.primary._05;
    case ClientVersionEnum.MAINTAINED:
      return colors.warning._05;
    default:
      return colors.black._00;
  }
};

const ClientVersionsTable: React.FC<ClientVersionsTablePropsType> = ({
  loading,
  data,
  updateClientVersion,
}) => {
  const handleChange = async (
    version: string,
    platform: ClientPlatformEnum,
    newStatus?: string,
  ) => {
    if (newStatus) {
      await updateClientVersion(version, platform, {
        status: newStatus as ClientVersionEnum,
      });
    }
  };

  return (
    <Table
      {...{
        loading,
        header: {
          cells: {
            0: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Версия
                </Typography>
              ),
            },
            1: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Платформа
                </Typography>
              ),
            },
            2: {
              width: 232,
              align: TableCellAlign.Left,
              children: (
                <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                  Статус
                </Typography>
              ),
            },
          },
        },
        rows:
          data?.map(({ version, status, platform }) => ({
            style: {
              backgroundColor: getRowBackgroundByStatus(status),
            },
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {version}
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <StatusBadge<typeof ClientPlatformEnum>
                    {...{
                      value: platform,
                      data: ClientPlatformBadgeMap,
                    }}
                  />
                ),
              },
              2: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <SingleSelect
                    {...{
                      value: findSelectedOption(
                        status,
                        ClientVersionStatusOptions,
                      ),
                      onChange: (newValue) =>
                        handleChange(version, platform, newValue?.value),
                      options: ClientVersionStatusOptions,
                      name: 'filter',
                      closeMenuOnSelect: true,
                      clearable: false,
                      components: { Option: IndicatorOption },
                    }}
                  />
                ),
              },
            },
          })) || [],
      }}
    />
  );
};

export default ClientVersionsTable;
