import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray, getIn } from 'formik';
import { ButtonsContainer, SubSectionBlock } from './styles';
import { Button, ConfirmDialog, toastify, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TypographySize,
  TypographyTag,
} from '#ui-kit.types';
import { isNullableValue, removeEmptyFields } from '#utils';
import debug from '#services/debug.service';
import { FranchisingService } from '#services/franchising';
import { initialValues, validationSchema } from './constants';
import {
  Input,
  Select,
  TextArea,
  Label,
  ErrorMessage,
  Section,
  SectionTitle,
  SubSection,
} from 'styles';
import {
  CreateFranchisorShopRequestDto,
  FranchisorShopResponseDto,
} from 'types';

interface FranchisorShopModalProps {
  onClose: () => void;
  initialData?: FranchisorShopResponseDto;
}

const FranchisorShopForm: React.FC<FranchisorShopModalProps> = ({
  onClose,
  initialData,
}) => {
  const franchisingService = new FranchisingService();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => setIsDialogOpen(false);

  // Если есть initialData (режим редактирования), инициализируем значения формы
  useEffect(() => {
    if (initialData) {
      Object.assign(initialValues, initialData);
    }
  }, [initialData]);

  // Обработка отправки формы
  const handleSubmit = async (values: CreateFranchisorShopRequestDto) => {
    const castedValues = validationSchema.cast(
      values,
    ) as CreateFranchisorShopRequestDto;
    const filledValues = removeEmptyFields(castedValues);

    // Отличаем отправку fiscalization пока что
    if (filledValues.fiscalization) {
      filledValues.fiscalization = undefined;
    }

    setIsLoading(true);

    if (initialData) {
      // Режим редактирования
      try {
        await franchisingService.updateFranchisorShop(
          initialData.id,
          filledValues,
        );

        onClose();

        toastify.success('Партнерская точка обновлена');
      } catch (error) {
        toastify.error('Не удалось обновить партнерскую точку');
        debug.error('🧑🏻‍💻 ~ updateFranchisorShop ~ err:', error);
      }
    } else {
      // Режим создания
      try {
        await franchisingService.createFranchisorShop(
          filledValues as CreateFranchisorShopRequestDto,
        );

        onClose();

        toastify.success('Партнерская точка создана');
      } catch (error) {
        toastify.error('Не удалось создать партнерскую точку');
        debug.error('🧑🏻‍💻 ~ createFranchisorShop ~ err:', error);
      }
    }

    setIsLoading(false);
  };

  const [dialogAction, setDialogAction] = useState<
    ((values: CreateFranchisorShopRequestDto) => Promise<void>) | (() => void)
  >(() => {});

  const handleConfirmClose = () => {
    setDialogAction(() => onClose);

    setIsDialogOpen(true);
  };

  const handleConfirmSubmit = () => {
    setDialogAction(() => handleSubmit);

    setIsDialogOpen(true);
  };

  return (
    <>
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          onClose={closeDialog}
          onConfirm={dialogAction}
          message="Подтвердите действие"
        />
      )}
      {initialData ? (
        <Typography
          size={TypographySize.s}
          tag={TypographyTag.p}
          style={{ marginBottom: 50 }}
        >
          * Если торговая точка уже была экспортирована во внешнюю систему, то
          во внешней системе отразятся только те изменения, которые
          ограничиваются данными "Реквизиты партнера маркетплейса для
          перечисления возмещения". Поэтому, если есть необходимость исправить
          другие поля, то, после применения изменений, следует повторить экспорт
          торговой точки, создав новый экземпляр в внешней системе
        </Typography>
      ) : (
        <></>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleConfirmSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            {/* Общая информация */}
            <Section>
              <SectionTitle>Общая информация</SectionTitle>
              {initialData?.id ? (
                <SubSection>
                  <Label required htmlFor="billingDescriptor">
                    Идентификатор магазина франчайзера
                  </Label>
                  <Input
                    id="id"
                    name="id"
                    value={initialData.id}
                    disabled={true}
                  />
                </SubSection>
              ) : (
                <></>
              )}
              {initialData?.externalId ? (
                <SubSection>
                  <Label required htmlFor="billingDescriptor">
                    Идентификатор магазина франчайзера во внешней системе
                  </Label>
                  <Input
                    id="id"
                    name="id"
                    value={initialData.externalId}
                    disabled={true}
                  />
                </SubSection>
              ) : (
                <></>
              )}
              <SubSection>
                <Label required htmlFor="billingDescriptor">
                  Название магазина в СМС и на странице проверки 3DS на
                  иностранном языке
                </Label>
                <Input
                  id="billingDescriptor"
                  name="billingDescriptor"
                  value={values.billingDescriptor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.billingDescriptor && touched.billingDescriptor && (
                  <ErrorMessage>{errors.billingDescriptor}</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="fullName">
                  Полное наименование организации
                </Label>
                <Input
                  id="fullName"
                  name="fullName"
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.fullName && touched.fullName && (
                  <ErrorMessage>{errors.fullName}</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="name">
                  Сокращенное наименование организации
                </Label>
                <Input
                  id="name"
                  name="name"
                  value={values.name ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="tin">
                  Идентификационный номер налогоплательщика (ИНН)
                </Label>
                <Input
                  id="tin"
                  name="tin"
                  value={values.tin ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.tin && touched.tin && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="comment">
                  Код причины постановки на учёт (КПП)
                </Label>
                <Input
                  id="rrc"
                  name="rrc"
                  value={values.rrc ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.rrc && touched.rrc && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="acea">
                  Общероссийский классификатор видов экономической деятельности
                  (ОКВЭД)
                </Label>
                <Input
                  id="acea"
                  name="acea"
                  value={values.acea ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.acea && touched.acea && (
                  <ErrorMessage>Некорректное значение для ОКВЭД</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="msrn">
                  Основной государственный регистрационный номер (ОГРН)
                </Label>
                <Input
                  id="msrn"
                  name="msrn"
                  value={values.msrn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.msrn && touched.msrn && (
                  <ErrorMessage>Некорректное значение для ОГРН</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="registrationDepartment">
                  Орган государственной регистрации
                </Label>
                <Input
                  id="registrationDepartment"
                  name="registrationDepartment"
                  value={values.registrationDepartment ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.registrationDepartment &&
                  touched.registrationDepartment && (
                    <ErrorMessage>Некорректное значение</ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="registrationDate">Дата присвоения ОГРН</Label>
                <Input
                  id="registrationDate"
                  name="registrationDate"
                  type="date"
                  value={values.registrationDate ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.registrationDate && touched.registrationDate && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="receivingEmail">
                  Email, на который будет выполняться отправка реестров
                </Label>
                <Input
                  id="receivingEmail"
                  name="receivingEmail"
                  value={values.receivingEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.receivingEmail && touched.receivingEmail && (
                  <ErrorMessage>Некорректный email</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="assets">
                  Сведения о величине зарегистрированного и оплаченного
                  уставного (складочного) капитала или величине уставного фонда,
                  имущества
                </Label>
                <Input
                  id="assets"
                  name="assets"
                  value={values.assets ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.assets && touched.assets && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="url">
                  Сайт франчайзера
                </Label>
                <Input
                  id="url"
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.url && touched.url && (
                  <ErrorMessage>Некорректный адрес</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="primaryActivities">
                  Основные виды деятельности
                </Label>
                <TextArea
                  id="primaryActivities"
                  name="primaryActivities"
                  value={values.primaryActivities ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                />
                {errors.primaryActivities && touched.primaryActivities && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="comment">Комментарий</Label>
                <TextArea
                  id="comment"
                  name="comment"
                  value={values.comment ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                />
                {errors.comment && touched.comment && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="comment">Электронная почта франчайзера</Label>
                <Input
                  id="shopEmail"
                  name="shopEmail"
                  value={values.shopEmail ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.comment && touched.comment && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label htmlFor="comment">MCC-код торговой группы</Label>
                <Input
                  id="mcc"
                  name="mcc"
                  value={values.mcc ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.comment && touched.comment && (
                  <ErrorMessage>Некорректное значение</ErrorMessage>
                )}
              </SubSection>
            </Section>

            {/* Адреса */}
            <Section>
              <SectionTitle required>Адреса</SectionTitle>
              <FieldArray name="addresses">
                {({ remove, push }) => (
                  <SubSection>
                    {values.addresses.length > 0 ? (
                      values.addresses.map((address, index) => (
                        <SubSectionBlock key={index}>
                          <SectionTitle>Адрес {index + 1}</SectionTitle>
                          <SubSection>
                            <Label required htmlFor={`addresses.${index}.type`}>
                              Тип адреса организации
                            </Label>
                            <Select
                              id={`addresses.${index}.type`}
                              name={`addresses.${index}.type`}
                              value={address.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Выберите тип</option>
                              <option value="LEGAL">Юридический</option>
                              <option value="ACTUAL">Фактический</option>
                              <option value="POSTAL">Почтовый</option>
                              <option value="OTHER">Другое</option>
                            </Select>
                            {getIn(errors, `addresses.${index}.type`) &&
                              getIn(touched, `addresses.${index}.type`) && (
                                <ErrorMessage>
                                  {getIn(errors, `addresses.${index}.type`)}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`addresses.${index}.postalCode`}>
                              Почтовый индекс
                            </Label>
                            <Input
                              id={`addresses.${index}.postalCode`}
                              name={`addresses.${index}.postalCode`}
                              value={address.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`addresses.${index}.country`}>
                              Трехбуквенный код страны по ISO
                            </Label>
                            <Input
                              id={`addresses.${index}.country`}
                              name={`addresses.${index}.country`}
                              value={address.country}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`addresses.${index}.city`}>
                              Город или населенный пункт
                            </Label>
                            <Input
                              id={`addresses.${index}.city`}
                              name={`addresses.${index}.city`}
                              value={address.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              required
                              htmlFor={`addresses.${index}.street`}
                            >
                              Улица, дом
                            </Label>
                            <Input
                              id={`addresses.${index}.street`}
                              name={`addresses.${index}.street`}
                              value={address.street}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(errors, `addresses.${index}.street`) &&
                              getIn(touched, `addresses.${index}.street`) && (
                                <ErrorMessage>
                                  {getIn(errors, `addresses.${index}.street`)}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`addresses.${index}.description`}>
                              Дополнительное описание
                            </Label>
                            <TextArea
                              id={`addresses.${index}.description`}
                              name={`addresses.${index}.description`}
                              value={address.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                            />
                          </SubSection>
                          <Button
                            label="Удалить адрес"
                            className="button"
                            onClick={() => remove(index)}
                            variant={ButtonVariant.Error}
                            size={ButtonSize.Medium}
                          />
                        </SubSectionBlock>
                      ))
                    ) : (
                      <></>
                    )}
                    <Button
                      label="Добавить адрес"
                      className="button"
                      onClick={() =>
                        push({
                          type: '',
                          postalCode: '',
                          country: '',
                          city: '',
                          street: '',
                          description: '',
                        })
                      }
                      size={ButtonSize.Medium}
                    />
                  </SubSection>
                )}
              </FieldArray>
            </Section>

            {/* Телефоны */}
            <Section>
              <SectionTitle required>Телефоны</SectionTitle>
              <FieldArray name="phones">
                {({ remove, push }) => (
                  <SubSection>
                    {values.phones.length > 0 ? (
                      values.phones.map((phone, index) => (
                        <SubSectionBlock key={index}>
                          <SectionTitle>Телефон {index + 1}</SectionTitle>
                          <SubSection>
                            <Label required htmlFor={`phones.${index}.type`}>
                              Тип телефона организации
                            </Label>
                            <Select
                              id={`phones.${index}.type`}
                              name={`phones.${index}.type`}
                              value={phone.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Выберите тип</option>
                              <option value="COMMON">Общий</option>
                              <option value="FAX">Факс</option>
                              <option value="OTHER">Другое</option>
                            </Select>
                            {getIn(errors, `phones.${index}.type`) &&
                              getIn(touched, `phones.${index}.type`) && (
                                <ErrorMessage>
                                  {getIn(errors, `phones.${index}.type`)}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label required htmlFor={`phones.${index}.phone`}>
                              Номер телефона
                            </Label>
                            <Input
                              id={`phones.${index}.phone`}
                              name={`phones.${index}.phone`}
                              value={phone.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(errors, `phones.${index}.phone`) &&
                              getIn(touched, `phones.${index}.phone`) && (
                                <ErrorMessage>
                                  {getIn(errors, `phones.${index}.phone`)}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`phones.${index}.description`}>
                              Дополнительное описание
                            </Label>
                            <TextArea
                              id={`phones.${index}.description`}
                              name={`phones.${index}.description`}
                              value={phone.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                            />
                          </SubSection>
                          <Button
                            label="Удалить телефон"
                            className="button"
                            onClick={() => remove(index)}
                            variant={ButtonVariant.Error}
                            size={ButtonSize.Medium}
                          />
                        </SubSectionBlock>
                      ))
                    ) : (
                      <></>
                    )}
                    <Button
                      label="Добавить телефон"
                      className="button"
                      onClick={() =>
                        push({
                          type: '',
                          phone: '',
                          description: '',
                        })
                      }
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.Medium}
                    />
                  </SubSection>
                )}
              </FieldArray>
            </Section>

            {/* Сведения о руководителе */}
            <Section>
              <SectionTitle required>Сведения о руководителе</SectionTitle>
              <SubSection>
                <Label required htmlFor="ceo.firstName">
                  Имя
                </Label>
                <Input
                  id="ceo.firstName"
                  name="ceo.firstName"
                  value={values.ceo.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.firstName') &&
                  getIn(touched, 'ceo.firstName') && (
                    <ErrorMessage>
                      {getIn(errors, 'ceo.firstName')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="ceo.lastName">
                  Фамилия
                </Label>
                <Input
                  id="ceo.lastName"
                  name="ceo.lastName"
                  value={values.ceo.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.lastName') &&
                  getIn(touched, 'ceo.lastName') && (
                    <ErrorMessage>{getIn(errors, 'ceo.lastName')}</ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="ceo.middleName">
                  Отчество
                </Label>
                <Input
                  id="ceo.middleName"
                  name="ceo.middleName"
                  value={values.ceo.middleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label required htmlFor="ceo.birthDate">
                  Дата рождения
                </Label>
                <Input
                  id="ceo.birthDate"
                  name="ceo.birthDate"
                  type="date"
                  value={values.ceo.birthDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.birthDate') &&
                  getIn(touched, 'ceo.birthDate') && (
                    <ErrorMessage>
                      {getIn(errors, 'ceo.birthDate')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.birthPlace">Место рождения</Label>
                <Input
                  id="ceo.birthPlace"
                  name="ceo.birthPlace"
                  value={values.ceo.birthPlace}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.docType">
                  Вид документа, удостоверяющего личность
                </Label>
                <Input
                  id="ceo.docType"
                  name="ceo.docType"
                  value={values.ceo.docType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.docNumber">Серия и номер документа</Label>
                <Input
                  id="ceo.docNumber"
                  name="ceo.docNumber"
                  value={values.ceo.docNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.issueDate">Дата выдачи документа</Label>
                <Input
                  id="ceo.issueDate"
                  name="ceo.issueDate"
                  type="date"
                  value={values.ceo.issueDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.issueDate') &&
                  getIn(touched, 'ceo.issueDate') && (
                    <ErrorMessage>
                      {getIn(errors, 'ceo.issueDate')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.issuedBy">Кем выдан документ</Label>
                <Input
                  id="ceo.issuedBy"
                  name="ceo.issuedBy"
                  value={values.ceo.issuedBy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label htmlFor="ceo.address">
                  Адрес регистрации/адрес проживания
                </Label>
                <Input
                  id="ceo.address"
                  name="ceo.address"
                  value={values.ceo.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label required htmlFor="ceo.phone">
                  Номер телефона
                </Label>
                <Input
                  id="ceo.phone"
                  name="ceo.phone"
                  value={values.ceo.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.phone') && getIn(touched, 'ceo.phone') && (
                  <ErrorMessage>{getIn(errors, 'ceo.phone')}</ErrorMessage>
                )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="ceo.country">
                  Гражданство (3-х буквенный код страны)
                </Label>
                <Input
                  id="ceo.country"
                  name="ceo.country"
                  value={values.ceo.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'ceo.country') &&
                  getIn(touched, 'ceo.country') && (
                    <ErrorMessage>{getIn(errors, 'ceo.country')}</ErrorMessage>
                  )}
              </SubSection>
            </Section>

            {/* Учредители */}
            <Section>
              <SectionTitle>
                Сведения об учредителях (Физические лица)
              </SectionTitle>
              <FieldArray name="founders.individuals">
                {({ remove, push }) => (
                  <SubSection>
                    {values.founders?.individuals?.length ? (
                      values.founders.individuals.map((individual, index) => (
                        <SubSectionBlock key={index}>
                          <SectionTitle>Учредитель {index + 1}</SectionTitle>
                          <SubSection>
                            <Label
                              required
                              htmlFor={`founders.individuals.${index}.firstName`}
                            >
                              Имя
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.firstName`}
                              name={`founders.individuals.${index}.firstName`}
                              value={individual.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(
                              errors,
                              `founders.individuals.${index}.firstName`,
                            ) &&
                              getIn(
                                touched,
                                `founders.individuals.${index}.firstName`,
                              ) && (
                                <ErrorMessage>
                                  {getIn(
                                    errors,
                                    `founders.individuals.${index}.firstName`,
                                  )}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label
                              required
                              htmlFor={`founders.individuals.${index}.lastName`}
                            >
                              Фамилия
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.lastName`}
                              name={`founders.individuals.${index}.lastName`}
                              value={individual.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(
                              errors,
                              `founders.individuals.${index}.lastName`,
                            ) &&
                              getIn(
                                touched,
                                `founders.individuals.${index}.lastName`,
                              ) && (
                                <ErrorMessage>
                                  {getIn(
                                    errors,
                                    `founders.individuals.${index}.lastName`,
                                  )}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.birthDate`}
                            >
                              Дата рождения
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.birthDate`}
                              name={`founders.individuals.${index}.birthDate`}
                              type="date"
                              value={individual.birthDate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.birthPlace`}
                            >
                              Место рождения
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.birthPlace`}
                              name={`founders.individuals.${index}.birthPlace`}
                              value={individual.birthPlace}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              required
                              htmlFor={`founders.individuals.${index}.citizenship`}
                            >
                              Гражданство
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.citizenship`}
                              name={`founders.individuals.${index}.citizenship`}
                              value={individual.citizenship}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(
                              errors,
                              `founders.individuals.${index}.citizenship`,
                            ) &&
                              getIn(
                                touched,
                                `founders.individuals.${index}.citizenship`,
                              ) && (
                                <ErrorMessage>
                                  {getIn(
                                    errors,
                                    `founders.individuals.${index}.citizenship`,
                                  )}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.docType`}
                            >
                              Вид документа, удостоверяющего личность
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.docType`}
                              name={`founders.individuals.${index}.docType`}
                              value={individual.docType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.docNumber`}
                            >
                              Серия и номер документа
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.docNumber`}
                              name={`founders.individuals.${index}.docNumber`}
                              value={individual.docNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.issueDate`}
                            >
                              Дата выдачи
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.issueDate`}
                              name={`founders.individuals.${index}.issueDate`}
                              value={individual.issueDate}
                              onChange={handleChange}
                              type="date"
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              htmlFor={`founders.individuals.${index}.issuedBy`}
                            >
                              Кем выдан
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.issuedBy`}
                              name={`founders.individuals.${index}.issuedBy`}
                              value={individual.issuedBy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label
                              required
                              htmlFor={`founders.individuals.${index}.address`}
                            >
                              Адрес регистрации/адрес проживания
                            </Label>
                            <Input
                              id={`founders.individuals.${index}.address`}
                              name={`founders.individuals.${index}.address`}
                              value={individual.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {getIn(
                              errors,
                              `founders.individuals.${index}.address`,
                            ) &&
                              getIn(
                                touched,
                                `founders.individuals.${index}.address`,
                              ) && (
                                <ErrorMessage>
                                  {getIn(
                                    errors,
                                    `founders.individuals.${index}.address`,
                                  )}
                                </ErrorMessage>
                              )}
                          </SubSection>
                          <Button
                            label="Удалить учредителя"
                            className="button"
                            onClick={() => remove(index)}
                            variant={ButtonVariant.Error}
                            size={ButtonSize.Medium}
                          />
                        </SubSectionBlock>
                      ))
                    ) : (
                      <></>
                    )}
                    <Button
                      label="Добавить учредителя"
                      className="button"
                      onClick={() =>
                        push({
                          firstName: '',
                          lastName: '',
                          middleName: '',
                          birthDate: '',
                          birthPlace: '',
                          citizenship: '',
                          docType: '',
                          docNumber: '',
                          issueDate: '',
                          issuedBy: '',
                          address: '',
                        })
                      }
                      size={ButtonSize.Medium}
                      variant={ButtonVariant.Primary}
                    />
                  </SubSection>
                )}
              </FieldArray>
              {/* {getIn(errors, 'founders.individuals') &&
                  getIn(touched, 'founders.individuals') && (
                    <ErrorMessage>
                      {getIn(errors, 'founders.individuals')}
                    </ErrorMessage>
                  )} */}
            </Section>

            {/* Лицензии */}
            <Section>
              <SectionTitle>Лицензии</SectionTitle>
              <FieldArray name="licenses">
                {({ remove, push }) => (
                  <SubSection>
                    {values.licenses?.length ? (
                      values.licenses.map((license, index) => (
                        <SubSectionBlock key={index}>
                          <SectionTitle>Лицензия {index + 1}</SectionTitle>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.type`}>
                              Вид лицензии
                            </Label>
                            <Input
                              id={`licenses.${index}.type`}
                              name={`licenses.${index}.type`}
                              value={license.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.number`}>
                              Номер лицензии
                            </Label>
                            <Input
                              id={`licenses.${index}.number`}
                              name={`licenses.${index}.number`}
                              value={license.number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.issueDate`}>
                              Дата выдачи
                            </Label>
                            <Input
                              id={`licenses.${index}.issueDate`}
                              name={`licenses.${index}.issueDate`}
                              value={
                                license.issueDate?.toString() ||
                                String(license.issueDate) ||
                                ''
                              }
                              type="date"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.issuedBy`}>
                              Кем выдан
                            </Label>
                            <Input
                              id={`licenses.${index}.issuedBy`}
                              name={`licenses.${index}.issuedBy`}
                              value={license.issuedBy}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.expiryDate`}>
                              Срок действия
                            </Label>
                            <Input
                              id={`licenses.${index}.expiryDate`}
                              name={`licenses.${index}.expiryDate`}
                              value={
                                license.expiryDate?.toString() ||
                                String(license.expiryDate) ||
                                ''
                              }
                              type="date"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <SubSection>
                            <Label htmlFor={`licenses.${index}.description`}>
                              Описание
                            </Label>
                            <Input
                              id={`licenses.${index}.description`}
                              name={`licenses.${index}.description`}
                              value={license.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </SubSection>
                          <Button
                            label="Удалить лицензию"
                            className="button"
                            onClick={() => remove(index)}
                            variant={ButtonVariant.Error}
                            size={ButtonSize.Medium}
                          />
                        </SubSectionBlock>
                      ))
                    ) : (
                      <></>
                    )}
                    <Button
                      label="Добавить лицензию"
                      className="button"
                      onClick={() =>
                        push({
                          type: '',
                          number: '',
                          issueDate: '',
                          issuedBy: '',
                          expiryDate: '',
                          description: '',
                        })
                      }
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.Medium}
                    />
                  </SubSection>
                )}
              </FieldArray>
            </Section>

            {/* Реквизиты партнера маркетплейса для перечисления возмещения */}
            <Section>
              <SectionTitle>
                Реквизиты партнера маркетплейса для перечисления возмещения
              </SectionTitle>
              <SubSection>
                <Label required htmlFor="bankAccount.account">
                  Расчетный или казначейский счет
                </Label>
                <Input
                  id="bankAccount.account"
                  name="bankAccount.account"
                  value={values.bankAccount.account}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'bankAccount.account') &&
                  getIn(touched, 'bankAccount.account') && (
                    <ErrorMessage>
                      {getIn(errors, 'bankAccount.account')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="bankAccount.korAccount">
                  Корреспондентский счет
                </Label>
                <Input
                  id="bankAccount.korAccount"
                  name="bankAccount.korAccount"
                  value={values.bankAccount.korAccount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label required htmlFor="bankAccount.bankName">
                  Наименование банка
                </Label>
                <Input
                  id="bankAccount.bankName"
                  name="bankAccount.bankName"
                  value={values.bankAccount.bankName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'bankAccount.bankName') &&
                  getIn(touched, 'bankAccount.bankName') && (
                    <ErrorMessage>
                      {getIn(errors, 'bankAccount.bankName')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="bankAccount.bic">
                  Банковский идентификационный код
                </Label>
                <Input
                  id="bankAccount.bic"
                  name="bankAccount.bic"
                  value={values.bankAccount.bic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'bankAccount.bic') &&
                  getIn(touched, 'bankAccount.bic') && (
                    <ErrorMessage>
                      {getIn(errors, 'bankAccount.bic')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label required htmlFor="bankAccount.details">
                  Назначение платежа
                </Label>
                <Input
                  id="bankAccount.details"
                  name="bankAccount.details"
                  value={values.bankAccount.details}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'bankAccount.details') &&
                  getIn(touched, 'bankAccount.details') && (
                    <ErrorMessage>
                      {getIn(errors, 'bankAccount.details')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="bankAccount.bcc">
                  Код бюджетной классификации
                </Label>
                <Input
                  id="bankAccount.bcc"
                  name="bankAccount.bcc"
                  value={
                    isNullableValue(values.bankAccount.bcc)
                      ? ''
                      : values.bankAccount.bcc
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label htmlFor="bankAccount.actme">
                  Общероссийский классификатор территорий муниципальных
                  образований
                </Label>
                <Input
                  id="bankAccount.actme"
                  name="bankAccount.actme"
                  value={
                    isNullableValue(values.bankAccount.actme)
                      ? ''
                      : values.bankAccount.actme
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
              <SubSection>
                <Label required htmlFor="bankAccount.tax">
                  Отчисления в пользу маркетплейса, % от суммы операции
                </Label>
                <Input
                  id="bankAccount.tax"
                  name="bankAccount.tax"
                  value={
                    isNullableValue(values.bankAccount.tax)
                      ? ''
                      : values.bankAccount.tax
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'bankAccount.tax') &&
                  getIn(touched, 'bankAccount.tax') && (
                    <ErrorMessage>
                      {getIn(errors, 'bankAccount.tax')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="bankAccount.taxFix">
                  Отчисления в пользу маркетплейса фиксированной минимальной
                  комиссии (сумма указывается в рублях, копейки отделяются
                  точкой)
                </Label>
                <Input
                  id="bankAccount.taxFix"
                  name="bankAccount.taxFix"
                  value={
                    isNullableValue(values.bankAccount.taxFix)
                      ? ''
                      : values.bankAccount.taxFix
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SubSection>
            </Section>

            {/* Фискализация */}
            <Section>
              <SectionTitle>Фискализация</SectionTitle>
              <SubSection>
                <Label htmlFor="fiscalization.company">Компания</Label>
                <Input
                  id="fiscalization.company"
                  name="fiscalization.company"
                  value={values.fiscalization?.сompany}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                  disabled={true}
                />
                {getIn(errors, 'fiscalization.company') &&
                  getIn(touched, 'fiscalization.company') && (
                    <ErrorMessage>
                      {getIn(errors, 'fiscalization.company')}
                    </ErrorMessage>
                  )}
              </SubSection>
              <SubSection>
                <Label htmlFor="fiscalization.notifyUrl">Notify URL</Label>
                <Input
                  id="fiscalization.notifyUrl"
                  name="fiscalization.notifyUrl"
                  value={values.fiscalization?.notifyUrl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {getIn(errors, 'fiscalization.notifyUrl') &&
                  getIn(touched, 'fiscalization.notifyUrl') && (
                    <ErrorMessage>
                      {getIn(errors, 'fiscalization.notifyUrl')}
                    </ErrorMessage>
                  )}
              </SubSection>
            </Section>

            {/* Кнопки сохранения и отмены */}
            <SubSection style={{ marginTop: '16px' }}>
              <ButtonsContainer>
                <Button
                  label="Сохранить"
                  className="button"
                  type="submit"
                  variant={ButtonVariant.Primary}
                  disabled={isLoading || initialData ? !dirty : !isValid}
                  size={ButtonSize.Medium}
                />
                <Button
                  label="Отмена"
                  type="reset"
                  onClick={handleConfirmClose}
                  variant={ButtonVariant.Error}
                  size={ButtonSize.Medium}
                />
              </ButtonsContainer>
            </SubSection>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FranchisorShopForm;
