import { FC } from 'react';
import { ButtonsContainer, ButtonWrapper } from './styles';
import {
  ButtonSize,
  ButtonVariant,
  TypographySize,
  TypographyTag,
} from '#ui-kit.types';
import Button from '../Button';
import Modal from '../Modal';
import Typography from '../Typography';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (...args: any[]) => any;
  message: string;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  message,
}) => (
  <Modal open={open} onClose={onClose}>
    <Typography
      size={TypographySize.xl}
      tag={TypographyTag.p}
      style={{ textAlign: 'center', margin: 'auto' }}
    >
      {message}
    </Typography>
    <ButtonsContainer>
      <ButtonWrapper>
        <Button
          label="Да"
          variant={ButtonVariant.Primary}
          onClick={onConfirm}
          size={ButtonSize.Small}
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          label="Нет"
          variant={ButtonVariant.Error}
          onClick={onClose}
          size={ButtonSize.Small}
        />
      </ButtonWrapper>
    </ButtonsContainer>
  </Modal>
);

export default ConfirmDialog;
